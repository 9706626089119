<template>
    <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
            <vx-card>
                <span>
                    <h4 class="mb-5" v-if="this.$route.params.id">Edit List Discount (Item)</h4>
                    <h4 class="mb-5" v-else>Create List Discount (Item)</h4>
                </span>
                <div class="vx-row mb-6" style="width:100%;">
                    <vs-button
                        class="ml-4 mt-2"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-arrow-left"
                        @click="handleBack()"
                    >Back</vs-button>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Code</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Code" v-model.lazy="data.code" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="name" :value="data.name" @change="data.name = $event.target.value"/>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('name')"
                            >{{ errors.first('name') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Notes</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <!-- <vs-input class="w-full" name="Notes" :value="data.notes" @change="data.notes = $event.target.value"/> -->
                        <vs-textarea class="w-full" maxlength ="255" name="Notes" :value="data.notes" @change="data.notes = $event.target.value" />
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Model</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <ul class="">
                            <li>
                                <input type="radio" value="Final" v-model="data.model"> Final
                            </li>
                            <li>
                                <input type="radio" value="Step Ladder" v-model="data.model"> Step Ladder
                            </li>
                            <span
                                class="text-danger text-sm"
                                v-show="errors.has('model')"
                                >{{ errors.first('model') }}
                            </span>
                        </ul>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid From</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker :disabled="read" v-validate="'required'" name="valid from" :inline="false" v-model.lazy="data.valid_from" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid from')"
                            >{{ errors.first('valid from') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid To</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker :disabled="read" v-validate="'required'" name="valid to" :inline="false" v-model.lazy="data.valid_to" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid to')"
                            >{{ errors.first('valid to') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Currency Code</span>
                    </div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <v-select v-validate="'required'" name="currency code" v-model.lazy="data.currency_code" label="Code" placeholder="Select currency code" :options="currency" @input="setSelectedCurrencyCode">
                            <template slot="option" slot-scope="option">
                                <div style="align-items: center;"><span>{{option.Code}}</span></div>
                            </template>
                        </v-select>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('currency code')"
                            >{{ errors.first('currency code') }}
                        </span>
                    </div>
                </div>
                <!--
                <div class="vx-row mb-6 ml-4 mr-4" v-if="this.$route.params.id == null">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Copy From</span>
					</div>
                    <div class="vx-col sm:w-4/5 w-full">
                        <v-select
                            @input="selectedDiscountList"
                            :options="discount.copy_value"
                            placeholder="List discount"
                            class="input-class"
                        />
                    </div>
				</div>
                -->
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Type</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <input type="radio" value="1" name="type" v-model="data.type"> Percentage &nbsp;
                        <input type="radio" value="2" name="type" v-model="data.type"> Fix Amount &nbsp;
                        <input type="radio" value="3" name="type" v-model="data.type"> Quantity Value &nbsp;
                        <input type="radio" value="4" name="type" v-model="data.type"> Amount (Multiply)
                        <span
                            class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Based On</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <input type="radio" value="1" name="base" v-model="data.base"> Amount &nbsp;
                        <input type="radio" value="2" name="base" v-model="data.base"> Quantity &nbsp;
                        <span
                            class="text-danger text-sm" v-show="errors.has('base')">{{ errors.first('base') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-1/5 w-full">
						<span>Option</span>
					</div>
                    <div class="vx-col sm:w-3/5 w-full">
                        <v-select v-model.lazy="newItem" label="label" placeholder="Select item" :options="optionItems" @input="setSelectedNewItem">
                            <template slot="option" slot-scope="option">
                                <div style="align-items: center;"><span>{{option.label}}</span></div>
                            </template>
                        </v-select>
                    </div>
                    <div class="vx-col sm:w-1/5 w-full">
                        <vs-button
                            v-on:click="handleAddNewItem()"
                            color="primary"
                            type="filled"
                            class="ml-3"
                        >add</vs-button>
                    </div>
				</div>
                <hr>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col w-full w-5/5 mb-2" v-for="(tr, indextr) in items" :key="indextr">
                        <div class="vx-row mb-2" style="display:flex">
                            <div class="vx-row" style="width:32%;">
                                <div style="margin: auto; width: 5%" >
                                    <span v-if="items[indextr].num != 0" >{{items[indextr].num}}.</span>
                                    <span v-else></span>
                                </div>
                                <div class="vx-col" style="margin: auto; width: 95%">
                                    <vs-input label="Item" class="w-full" :value="items[indextr].label" readonly/>
                                </div>
                            </div>
                            <div class="vx-row vx-col" style="width:64%;">
                                <div class="vx-col" style="marginLauto; width:15%">
                                    <vs-input style="max-width:130px;" @keypress="isNumber($event)" :label=discountBaseLabel() :value="items[indextr].qty_from" @change="items[indextr].qty_from = $event.target.value" min="0" name="QtyFrom"/>
                                </div>
                                <div class="vx-col" style="marginLauto; width:15%">
                                    <vs-input style="max-width:130px;" @keypress="isNumber($event)" :label=discountBaseLabel2() :value="items[indextr].qty_to" @change="items[indextr].qty_to = $event.target.value" min="0" name="QtyTo"/>
                                </div>
                                <div class="vx-col" style="marginLauto; width:23%">
                                    <vs-input @keypress="isNumber($event)" :label=discountTypeLabel(items[indextr]) :value="items[indextr].discount" @change="items[indextr].discount = $event.target.value" name="Discount"/>
                                </div>
                                <div class="vx-col" style="marginLauto; width:23%" v-if="showAdditionalDiscount">
                                    <vs-input @keypress="isNumber($event)" :label=discountTypeLabel2(items[indextr]) :value="items[indextr].additional_discount" @change="items[indextr].additional_discount = $event.target.value" :readonly="readonlyAdditionalDiscount" name="Additional Discount"/>
                                </div>
                                <div class="vx-col" style="marginLauto; width:23%" v-if="showCap">
                                    <vs-input @keypress="isNumber($event)" label="Cap" :value="items[indextr].cap" @change="items[indextr].cap = $event.target.value" :readonly="readonlyCap" name="Cap "/>
                                </div>
                                <div class="vx-col" style="marginLauto; width:23%" v-if="showDiscountPer">
                                    <vs-input @keypress="isNumber($event)" :label=discountTypeLabel3() :value="items[indextr].discount_per" @change="items[indextr].discount_per = $event.target.value" :readonly="readonlyDiscountPer" name="Discount Per "/>
                                </div>
                            </div>
                            <div class="vx-col" style="width:4%; margin:auto;">
                                <label for="" class="vs-input--label"></label>
                                <vs-button
                                    v-if="items[indextr].status=='minus'"
                                    v-on:click="handleRemoveItem(indextr)"
                                    color="danger"
                                    type="filled"
                                    icon-pack="feather"
                                    icon="icon-minus"
                                ></vs-button>
                                <vs-button
                                    v-else
                                    v-on:click="handleAddItem(indextr, tr)"
                                    color="success"
                                    type="filled"
                                    icon-pack="feather"
                                    icon="icon-plus"
                                ></vs-button>
                            </div>
                        </div>
                    </div>
				</div>
                <content-placeholders v-if="statusPlaceholder == true" class="mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-5/5 w-full mb-2">
                        <div class="vx-row mb-2">
                            <div class="vx-col w-2/5" style="display: flex">
                                <content-placeholders-text style="margin: auto; width: 5%" class="ml-4 mr-3" :lines="1" />
                                <content-placeholders-text class="w-full" :lines="2" />
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                            </div>
                            <div class="vx-col w-1/5" style="display: flex">
                                <div class="vx-col w-1/2">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <!-- <div class="vx-col w-1/2" style="text-align: center; margin: auto; margin-bottom: 0">
                                    <content-placeholders-text class="w-full" :lines="3" />
                                </div> -->
                            </div>
                        </div>
                    </div>
                </content-placeholders>
                <!-- <div v-else>Loading Data</div> -->

                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col">
						<vs-button class="mr-3 mb-2" @click="handleSubmit" v-if="items.length > 0">Submit</vs-button>
					</div>
				</div>
                
            </vx-card>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    created() {
        // this.getDataListDiscount() // untuk copy
        this.getCurrencyCode()
    },
    mounted() {
        if(!(this.$route.params.id)){
            this.getCode();
        } else {
            this.getData();
        }
        this.getItemUnit();
    },
    data() {
        return {
            read: false,
            discount:{
                copy_id: null,
                copy_name: null,
                copy_value: []
            },
            currency: [],
            newItem: null,
            optionItems: [],
            items: [],
            data: {
                code: "",
                name: "",
                notes: "",
                model: false,
                valid_from: "",
                valid_to: "",
                currency_code: "",
                type: "",
                base: ""
            },
            statusPlaceholder: true,
            readonlyAdditionalDiscount: false,
            readonlyCap: false,
            readonlyDiscountPer: false,
            showAdditionalDiscount:false,
            showCap:false,
            showDiscountPer: false
        };
    },
    methods: {
        discountBaseLabel() {
            if(this.data.base==1) {
                return "Amount from"
            } else if(this.data.base==2) {
                return "Quantity from"
            } else {
                return "From"
            }
        },
        discountBaseLabel2() {
            if(this.data.base==1) {
                return "Amount to"
            } else if(this.data.base==2) {
                return "Quantity to"
            } else {
                return "To"
            }
        },
        discountTypeLabel(item) {
            if(this.data.type==1) {
                return "Discount (%)"
            } else if(this.data.type==2) {
                return "Discount ("+this.data.currency_code+")"
            } else if(this.data.type==3) {
                return "Discount ("+item.item_unit+")"
            } else {
                return "Discount"
            }
        },
        discountTypeLabel2(item) {
            if(this.data.type==1) {
                return "Additional Discount (%)"
            } else if(this.data.type==2) {
                return "Additional Discount ("+this.data.currency_code+")"
            } else if(this.data.type==3) {
                return "Additional Discount ("+item.item_unit+")"
            } else {
                return "Additional Discount"
            }
        },
        discountTypeLabel3() {
            if(this.data.type==2) {
                return "Additional Discount ("+this.data.currency_code+")"
            } else {
                return "Discount Per"
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        set(v){
            console.log(v)
        },
        handleBack() {
            this.$router.push("/master/pricing-management-v2");
        },
        setSelectedCurrencyCode(value) {
            this.data.currency_code = value.Code;
        },
        setSelectedNewItem(value) {
            this.newItem = value;
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        handleAddItem(indextr, tr){
            this.items.splice(indextr+1, 0, {
                num: 0,
                label: tr.label,
                item_name: tr.item_name,
                sku_code: tr.sku_code,
                item_unit: tr.item_unit,
                qty_from: "0",
                qty_to: "0",
                discount: "0",
                additional_discount: "0",
                cap: "0",
                discount_per: "0",
                status: "minus",
            })
        },
        handleRemoveItem(indextr){
            this.items.splice(indextr, 1)
        },
        handleAddNewItem(){
            let selected = this.newItem
            let check = this.items.filter(function(ele){
                return (ele.sku_code == selected.sku_code && ele.item_unit == selected.item_unit && ele.item_name == selected.item_name)
            });
            if(check.length > 0) {
                this.$vs.notify({
                    title: "Warning",
                    text: "Duplicate item detected, please select another item",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            } else {
                let length = this.items.length
                let newNum = 1
                if(length > 0) {
                    newNum = this.items[length-1].num + 1
                }
                this.items.push({
                    num: newNum,
                    label: this.newItem.label,
                    item_name: this.newItem.item_name,
                    sku_code: this.newItem.sku_code,
                    item_unit: this.newItem.item_unit,
                    qty_from: "0",
                    qty_to: "0",
                    discount: "0",
                    additional_discount: "0",
                    cap: "0",
                    discount_per: "0",
                    status: "plus"
                })
                this.newItem = null
            }
        },
        getCurrencyCode(){
            this.$http
            .get("/api/v1/master/currency").then(resp => {
                if (resp.status == "success") {
                    this.currency = resp.data.records;
                }
            });
        },
        getCode() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/discount/code")
            .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data.code = resp.data
                    // this.getItemUnit();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getDataListDiscount() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/discounts")
            .then(resp => {
            if (resp.status == "success") {
                var _this = this
                resp.data.records.forEach(function(element){
                    _this.discount.copy_value.push({
                        id: element.id,
                        label: element.code + " - " + element.name
                    })
                })

                this.$vs.loading.close();
            }
            });
        },
        getItemUnit() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/item-unit")
            .then(resp => {
                this.$vs.loading.close();
                var _this = this
                if (resp.code == 200) {
                    for(var k = 0; k < resp.data.length; k++){
                        _this.optionItems.push({
                            num: k+1,
                            label: resp.data[k].label,
                            item_name: resp.data[k].item_name,
                            sku_code: resp.data[k].sku_code,
                            item_unit: resp.data[k].item_unit,
                            qty_from: "0",
                            qty_to: "0",
                            discount: "0",
                            additional_discount: "0",
                            cap: "0",
                            discount_per: "0",
                            status: "plus"
                        })
                    }
                    if(_this.optionItems.length == resp.data.length){
                        _this.statusPlaceholder = false
                    }
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        // selectedDiscountList(val){
        //     this.$http
        //     .get("/api/v1/master/discount/" + val.id)
        //     .then(resp => {
        //         var _this = this
        //         _this.items = []
        //         this.$vs.loading.close();
        //         if (resp.code == 200) {
        //             this.statusPlaceholder = true
                    
        //             setTimeout(function(){
        //                 for(var k = 0; k < resp.data.list.length; k++){
        //                     _this.items.push({
        //                         num: resp.data.list[k].num,
        //                         label: resp.data.list[k].label,
        //                         status: resp.data.list[k].status,
        //                         qty_from: resp.data.list[k].qty_from.toString(),
        //                         qty_to: resp.data.list[k].qty_to.toString(),
        //                         discount: resp.data.list[k].discount.toString(),
        //                         additional_discount: resp.data.list[k].additional_discount.toString(),
        //                         cap: resp.data.list[k].cap.toString(),
        //                         sku_code: resp.data.list[k].sku_code,
        //                         item_unit: resp.data.list[k].item_unit,
        //                         item_name: resp.data.list[k].item_name,
        //                         discount_per: resp.data.list[k].discount_per,
        //                     })
                            
        //                 }
        //                 if(_this.items.length == resp.data.list.length){
        //                     _this.statusPlaceholder = false
        //                     _this.getNewItem(val.id)
        //                 }
        //             }, 0); 

        //         } else {
        //             this.$vs.notify({
        //                 color: "danger",
        //                 title: "Error",
        //                 text: resp.message,
        //                 position: "top-right",
        //                 iconPack: "feather",
        //                 icon: "icon-x-circle"
        //             });
        //         }
        //     })
        //     .catch(error => {
        //         this.$vs.loading.close();
        //         console.log(error);
        //     });

        // },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if(this.data.name == ""){
                    this.errors.add({
                        field: "name",
                        msg: "The name is required"
                    });
                    result = false;
                }
                if(this.data.model == false){
                    this.errors.add({
                        field: "model",
                        msg: "The model is required"
                    });
                    result = false;
                }
                if (result) {
                    this.$vs.loading();
                    var _this = this
                    this.items.forEach(function(element, index){
                        _this.items[index].qty_from = element.qty_from.toString()
                        _this.items[index].qty_to = element.qty_to.toString()
                        // _this.items[index].discount = parseFloat(element.discount)
                        _this.items[index].discount = element.discount.toString()
                        _this.items[index].additional_discount = element.additional_discount.toString()
                        _this.items[index].cap = element.cap.toString()
                        _this.items[index].discount_per = element.discount_per.toString()
                    })

                    console.log("paramData", this.paramData())
                    if (this.$route.params.id) {
                        this.putData();
                    } else {
                        this.postData();
                    }
                }
            });
        },
        paramData() {
            return {
                discount_id: parseInt(this.$route.params.id),
                code: this.data.code,
                name: this.data.name,
                notes: this.data.notes,
                model: this.data.model,
                valid_from: this.data.valid_from,
                valid_to: this.data.valid_to,
                currency_code: this.data.currency_code,
                type: parseInt(this.data.type),
                base: parseInt(this.data.base),
                item: this.items
            };
        },
        postData() {
            this.$http
                .post("/api/v2/master/discount", this.paramData())
                .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "New List Discount Created",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                    this.$router.push("/master/pricing-management-v2");
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        putData() {
        this.$http
            .put("/api/v2/master/discount/" + this.$route.params.id, this.paramData())
            .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "List Discount Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getData() {
            this.$vs.loading();
            this.$http
            .get("/api/v2/master/discount/" + this.$route.params.id)
            .then(resp => {
                var _this = this
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data.code = resp.data.code;
                    this.data.name = resp.data.name;
                    this.data.notes = resp.data.notes;
                    this.data.model = resp.data.model;
                    this.data.valid_from = resp.data.valid_from;
                    this.data.valid_to = resp.data.valid_to;
                    this.data.currency_code = resp.data.currency_code;
                    this.data.type = resp.data.type;
                    this.data.base = resp.data.base;
                    this.read = (resp.data.discount_rule_usage > 0) ? true : false;

                    // this.getData1()
                    this.statusPlaceholder = true
                    
                    setTimeout(function(){
                        for(var k = 0; k < resp.data.list.length; k++){
                            _this.items.push({
                                num: resp.data.list[k].num,
                                label: resp.data.list[k].label,
                                status: resp.data.list[k].status,
                                qty_from: resp.data.list[k].qty_from.toString(),
                                qty_to: resp.data.list[k].qty_to.toString(),
                                discount: resp.data.list[k].discount.toString(),
                                sku_code: resp.data.list[k].sku_code,
                                item_unit: resp.data.list[k].item_unit,
                                item_name: resp.data.list[k].item_name,
                                additional_discount: resp.data.list[k].additional_discount.toString(),
                                cap: resp.data.list[k].cap.toString(),
                                discount_per: resp.data.list[k].discount_per.toString(),
                            })
                            
                        }
                        if(_this.items.length == resp.data.list.length){
                            _this.statusPlaceholder = false
                        }
                    }, 0); 

                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        // getNewItem(val) {
        //     this.$http
        //     .get("/api/v2/master/new-item-unit/" + val)
        //     .then(resp => {
        //         var _this = this
        //         this.$vs.loading.close();
        //         if (resp.code == 200) {
                    
        //             setTimeout(function(){
        //                 var x = 0
        //                 _this.items.forEach(function(element){
        //                     if(element.num != 0){
        //                         x++
        //                     }
        //                 })
        //                 for(var k = 0; k < resp.data.length; k++){
        //                     _this.optionItems.push({
        //                         num: x+k+1,
        //                         label: resp.data[k].label,
        //                         item_name: resp.data[k].item_name,
        //                         sku_code: resp.data[k].sku_code,
        //                         item_unit: resp.data[k].item_unit,
        //                         qty_from: "0",
        //                         qty_to: "0",
        //                         discount: "0",
        //                         additional_discount: "0",
        //                         cap: "0",
        //                         discount_per: "0",
        //                         status: "plus"
        //                     })
                            
        //                 }
        //                 if(_this.optionItems.length == resp.data.length){
        //                     _this.statusPlaceholder = false
        //                 }
        //             }, 0); 

        //         } else {
        //             this.$vs.notify({
        //                 color: "danger",
        //                 title: "Error",
        //                 text: resp.message,
        //                 position: "top-right",
        //                 iconPack: "feather",
        //                 icon: "icon-x-circle"
        //             });
        //         }
        //     })
        //     .catch(error => {
        //         this.$vs.loading.close();
        //         console.log(error);
        //     });
        // },
    },
    computed: {},
    watch: {
        "data.name": function(val) {
            if(val == ""){
                this.errors.add({
                    field: "name",
                    msg: "The name is required"
                });
            } else {
                this.errors.clear()
            }
        },
        "data.model": function(val) {
            console.log("model", val)
            if(val == false){
                this.errors.add({
                    field: "model",
                    msg: "The model is required"
                });
            } else {
                this.errors.clear()
            }
        },
        "data.type": function(val) {
            console.log("type", val)
            if(val == 1) {
                // percentage
                for(var i = 0; i < this.items.length; i++){
                    this.items[i].discount_per = 0
                }

                this.readonlyAdditionalDiscount = false
                this.readonlyCap = false
                this.readonlyDiscountPer = true
                
                this.showAdditionalDiscount = true
                this.showCap = true
                this.showDiscountPer = false
            } else if(val == 2 || val == 3) {
                // fix amount or qty value
                for(var j = 0; j < this.items.length; j++){
                    this.items[j].additional_discount = 0
                    this.items[j].cap = 0
                    this.items[j].discount_per = 0
                }
                this.readonlyAdditionalDiscount = true
                this.readonlyCap = true
                this.readonlyDiscountPer = true

                this.showAdditionalDiscount = false
                this.showCap = false
                this.showDiscountPer = false
            } else if(val==4) {
                // amount (multiple)
                for(var k = 0; k < this.items.length; k++){
                    this.items[k].additional_discount = 0
                    this.items[k].cap = 0
                }
                this.readonlyAdditionalDiscount = true
                this.readonlyCap = true
                this.readonlyDiscountPer = false
                
                this.showAdditionalDiscount = false
                this.showCap = false
                this.showDiscountPer = true
            }
        },
    }
};
</script>